export const makeStrapiFilters = (filterArr) => {
  if (!filterArr || !filterArr.length) return "";

  let filters = [];

  filterArr.forEach(({ type, keyName, keyFilter, keyValue }) => {
    if (Array.isArray(keyValue) && type === "filters" && keyFilter === "$or") {
      keyValue.forEach((val, index) => {
        filters.push(`filters[$or][${index}][${keyName}][$eq]=${val}`);
      });
    } else {
      filters.push(
        keyFilter ? `${type}[${keyName}][${keyFilter}]=${keyValue}` : `${type}[${keyName}]=${keyValue}`
      );
    }
  });

  return filters.join("&");
};


export const STRAPI_TRANSACTIONS = "transactions";

