import { makeStrapiFilters, STRAPI_TRANSACTIONS } from '../../config/configStrapiUrls';
import { fetchCurrentUser } from '../../ducks/user.duck';
import {
  getMyTournaments,
  getRegisteredOpenTransactionsAll,
  onGetApiCollecton,
  updateTransactionById,
} from '../../util/api';
import { storableError } from '../../util/errors';

// ================ Action types ================ //

export const FETCH_TRANSACTIONS_REQUEST = 'app/MyTournamentPage/FETCH_TRANSACTIONS_REQUEST';
export const FETCH_TRANSACTIONS_SUCCESS = 'app/MyTournamentPage/FETCH_TRANSACTIONS_SUCCESS';
export const FETCH_TRANSACTIONS_ERROR = 'app/MyTournamentPage/FETCH_TRANSACTIONS_ERROR';

export const UPDATE_TRANSACTIONS_REQUEST = 'app/MyTournamentPage/UPDATE_TRANSACTIONS_REQUEST';
export const UPDATE_TRANSACTIONS_SUCCESS = 'app/MyTournamentPage/UPDATE_TRANSACTIONS_SUCCESS';
export const UPDATE_TRANSACTIONS_ERROR = 'app/MyTournamentPage/UPDATE_TRANSACTIONS_ERROR';

// ================ Reducer ================ //

const initialState = {
  transactions: [],
  transactionsInProgress: false,
  transactionsError: null,

  updateTransactions: [],
  updateTransactionsInProgress: false,
  updateTransactionsError: null,
};

const myTournamentPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_TRANSACTIONS_REQUEST:
      return {
        ...state,
        transactionsInProgress: true,
        transactionsError: null,
      };
    case FETCH_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        transactions: payload.data,
        transactionsInProgress: false,
      };
    case FETCH_TRANSACTIONS_ERROR:
      return { ...state, transactionsInProgress: false, transactionsError: payload };

    case UPDATE_TRANSACTIONS_REQUEST:
      return {
        ...state,
        updateTransactionsInProgress: true,
        updateTransactionsError: null,
      };
    case UPDATE_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        updateTransactions: payload.data,
        updateTransactionsInProgress: false,
      };
    case UPDATE_TRANSACTIONS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, updateTransactionsInProgress: false, updateTransactionsError: payload };

    default:
      return state;
  }
};

export default myTournamentPageReducer;

// ================ Action creators ================ //

// This works the same way as addMarketplaceEntities,
// but we don't want to mix own listings with searched listings
// (own listings data contains different info - e.g. exact location etc.)

export const queryTransactionRequest = () => ({
  type: FETCH_TRANSACTIONS_REQUEST,
  // payload: { queryParams },
});

export const queryTransactionSuccess = response => ({
  type: FETCH_TRANSACTIONS_SUCCESS,

  payload: { data: response },
});

export const queryTransactionError = e => ({
  type: FETCH_TRANSACTIONS_ERROR,
  error: true,
  payload: e,
});

export const updateTransRequest = () => ({
  type: UPDATE_TRANSACTIONS_REQUEST,
  // payload: { queryParams },
});

export const updateTransSuccess = response => ({
  type: UPDATE_TRANSACTIONS_SUCCESS,
  payload: { data: response?.data },
});

export const updateTransError = e => ({
  type: UPDATE_TRANSACTIONS_ERROR,
  error: true,
  payload: e,
});
export const queryTransaction = () => (dispatch, getState, sdk) => {
  const currentUser = getState().user.currentUser || {};
  dispatch(queryTransactionRequest());
  // const searchFilterArray = [
  //   { type: 'pagination', keyName: 'page', keyFilter: '', keyValue: 1 },
  //   { type: 'pagination', keyName: 'pageSize', keyFilter: '', keyValue: 10 },
  //   {
  //     type: 'filters',
  //     keyName: 'customerId',
  //     keyFilter: '$eq',
  //     keyValue: currentUser?.id?.uuid ?? '',
  //   },
  //   {
  //     type: 'filters',
  //     keyName: 'lastTransition',
  //     keyFilter: '$or',
  //     keyValue: ['transition/accept', 'transition/confirm-payment'],
  //   },
  // ];
  // return onGetApiCollecton({
  //   API_TYPE: STRAPI_TRANSACTIONS,
  //   data: { searchFilter: makeStrapiFilters(searchFilterArray) },
  // })
  //   .then(async response => {
  //     const refined = await Promise.all(response.data.map(async (data, index) => {
  //       const transactionData = await getRegisteredOpenTransactionsAll({ listingId: data.listingId });

  //       // Merge transactionData into the corresponding index
  //       return {
  //         ...data,
  //         transactions: transactionData.data.data // Assuming transactionData.data.data is an array
  //       };
  //     }));

  //     await dispatch(queryTransactionSuccess(refined));
  //     return refined;
  //   })
  //   .catch(e => {
  //     dispatch(queryTransactionError(storableError(e)));
  //     throw e;
  //   });
  return getMyTournaments({ currentUserId: currentUser?.id?.uuid })
    .then(async res => {
      await dispatch(queryTransactionSuccess(res.data));
    })
    .catch(e => {
      dispatch(queryTransactionError(storableError(e)));
    });
};

export const updateTransaction = params => (dispatch, getState, sdk) => {
  dispatch(updateTransRequest());

  const { updateParams } = params;

  return updateTransactionById(updateParams)
    .then(async response => {
      await dispatch(updateTransSuccess(response));
      await dispatch(queryTransaction());
      return response;
    })
    .catch(e => {
      dispatch(updateTransError(storableError(e)));
    });
};

export const loadData = () => (dispatch, getState, sdk) => {
  return Promise.all([dispatch(fetchCurrentUser())])
    .then(response => {
      dispatch(queryTransaction());
      return response;
    })
    .catch(e => {
      throw e;
    });
};
