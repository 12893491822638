import { denormalisedEntities, onGetTransactionParam } from '../../util/data';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { types as sdkTypes } from '../../util/sdkLoader';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import moment from 'moment';
import {
  checkandCreateNewUser,
  onCheckUserAlreadyExist,
  onCreateTransaction,
  onGetApiCollecton,
  updateTransactionById,
} from '../../util/api';
import { makeStrapiFilters, STRAPI_TRANSACTIONS } from '../../config/configStrapiUrls';

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 42 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const { UUID } = sdkTypes;

// ================ Action types ================ //

export const FETCH_LISTINGS_REQUEST = 'app/RegistrationPage/FETCH_LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS = 'app/RegistrationPage/FETCH_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR = 'app/RegistrationPage/FETCH_LISTINGS_ERROR';

export const FETCH_TIMESLOTS_REQUEST = 'app/RegistrationPage/FETCH_TIMESLOTS_REQUEST';
export const FETCH_TIMESLOTS_SUCCESS = 'app/RegistrationPage/FETCH_TIMESLOTS_SUCCESS';
export const FETCH_TIMESLOTS_ERROR = 'app/RegistrationPage/FETCH_TIMESLOTS_ERROR';

export const FETCH_TRANSACTIONS_REQUEST = 'app/RegistrationPage/FETCH_TRANSACTIONS_REQUEST';
export const FETCH_TRANSACTIONS_SUCCESS = 'app/RegistrationPage/FETCH_TRANSACTIONS_SUCCESS';
export const FETCH_TRANSACTIONS_ERROR = 'app/RegistrationPage/FETCH_TRANSACTIONS_ERROR';

export const CREATE_TRANSACTIONS_REQUEST = 'app/RegistrationPage/CREATE_TRANSACTIONS_REQUEST';
export const CREATE_TRANSACTIONS_SUCCESS = 'app/RegistrationPage/CREATE_TRANSACTIONS_SUCCESS';
export const CREATE_TRANSACTIONS_ERROR = 'app/RegistrationPage/CREATE_TRANSACTIONS_ERROR';

export const UPDATE_TRANSACTIONS_REQUEST = 'app/RegistrationPage/UPDATE_TRANSACTIONS_REQUEST';
export const UPDATE_TRANSACTIONS_SUCCESS = 'app/RegistrationPage/UPDATE_TRANSACTIONS_SUCCESS';
export const UPDATE_TRANSACTIONS_ERROR = 'app/RegistrationPage/UPDATE_TRANSACTIONS_ERROR';

export const USER_ALREADY_EXIST_REQUEST = 'app/RegistrationPage/USER_ALREADY_EXIST_REQUEST';
export const USER_ALREADY_EXIST_SUCCESS = 'app/RegistrationPage/USER_ALREADY_EXIST_SUCCESS';
export const USER_ALREADY_EXIST_ERROR = 'app/RegistrationPage/USER_ALREADY_EXIST_ERROR';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  queryParams: null,
  queryInProgress: false,
  queryListingsError: null,
  currentPageResultIds: [],

  timeSlots: [],
  timeSlotsInProgress: false,
  timeSlotsError: null,

  transactions: [],
  transactionsInProgress: false,
  transactionsError: null,

  createTransactions: [],
  createTransactionsInProgress: false,
  createTransactionsError: null,

  updateTransactions: [],
  updateTransactionsInProgress: false,
  updateTransactionsError: null,

  isUserExist: null,
  userExistInProgress: false,
  userExistError: null
};

const resultIds = data => {
  const listings = data.data;
  return listings
    .filter(l => !l.attributes.deleted && l.attributes.state === 'published')
    .map(l => l.id);
};

const registrationPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_LISTINGS_REQUEST:
      return {
        ...state,
        queryParams: payload.queryParams,
        queryInProgress: true,
        queryListingsError: null,
        currentPageResultIds: [],
      };
    case FETCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        queryInProgress: false,
      };
    case FETCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, queryInProgress: false, queryListingsError: payload };

    case FETCH_TIMESLOTS_REQUEST:
      return {
        ...state,
        timeSlotsInProgress: true,
        timeSlotsError: null,
      };
    case FETCH_TIMESLOTS_SUCCESS:
      return {
        ...state,
        timeSlots: payload.data,
        timeSlotsInProgress: false,
      };
    case FETCH_TIMESLOTS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, timeSlotsInProgress: false, timeSlotsError: payload };

    case FETCH_TRANSACTIONS_REQUEST:
      return {
        ...state,
        transactionsInProgress: true,
        transactionsError: null,
      };
    case FETCH_TRANSACTIONS_SUCCESS:
      
      return {
        ...state,
        transactions: payload.data,
        transactionsInProgress: false,
      };
    case FETCH_TRANSACTIONS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, transactionsInProgress: false, transactionsError: payload };

    case CREATE_TRANSACTIONS_REQUEST:
      return {
        ...state,
        createTransactionsInProgress: true,
        createTransactionsError: null,
      };
    case CREATE_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        createTransactions: payload.data,
        createTransactionsInProgress: false,
      };
    case CREATE_TRANSACTIONS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, createTransactionsInProgress: false, createTransactionsError: payload };

    case UPDATE_TRANSACTIONS_REQUEST:
      return {
        ...state,
        updateTransactionsInProgress: true,
        updateTransactionsError: null,
      };
    case UPDATE_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        updateTransactions: payload.data,
        updateTransactionsInProgress: false,
      };
    case UPDATE_TRANSACTIONS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, updateTransactionsInProgress: false, updateTransactionsError: payload };

    case USER_ALREADY_EXIST_REQUEST:
      return {
        ...state,
        userExistInProgress: true,
        userExistError: null,
        isUserExist: false,
      };
    case USER_ALREADY_EXIST_SUCCESS:

      return {
        ...state,
        isUserExist: payload,
        userExistInProgress: false,
      };
    case USER_ALREADY_EXIST_ERROR:
      return {
        ...state,
        userExistInProgress: false,
        userExistError: payload,
        isUserExist: false,
      };

    default:
      return state;
  }
};

export default registrationPageReducer;

// ================ Selectors ================ //

/**
 * Get the denormalised own listing entities with the given IDs
 *
 * @param {Object} state the full Redux store
 * @param {Array<UUID>} listingIds listing IDs to select from the store
 */
export const getOwnListingsById = (state, listingIds) => {
  const { ownEntities } = state.ManageListingsPage;
  const resources = listingIds.map(id => ({
    id,
    type: 'ownListing',
  }));
  const throwIfNotFound = false;
  return denormalisedEntities(ownEntities, resources, throwIfNotFound);
};

// ================ Action creators ================ //

// This works the same way as addMarketplaceEntities,
// but we don't want to mix own listings with searched listings
// (own listings data contains different info - e.g. exact location etc.)

export const queryListingsRequest = queryParams => ({
  type: FETCH_LISTINGS_REQUEST,
  payload: { queryParams },
});

export const queryListingsSuccess = response => ({
  type: FETCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const queryListingsError = e => ({
  type: FETCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});
export const queryTimeSlotsRequest = () => ({
  type: FETCH_TIMESLOTS_REQUEST,
  // payload: { queryParams },
});

export const queryTimeSlotsSuccess = response => ({
  type: FETCH_TIMESLOTS_SUCCESS,
  payload: { data: response.data },
});

export const queryTimeSlotsError = e => ({
  type: FETCH_TIMESLOTS_ERROR,
  error: true,
  payload: e,
});
export const queryTransactionRequest = () => ({
  type: FETCH_TRANSACTIONS_REQUEST,
  // payload: { queryParams },
});

export const queryTransactionSuccess = response => ({
  type: FETCH_TRANSACTIONS_SUCCESS,
  payload: { data: response.data },
});

export const queryTransactionError = e => ({
  type: CREATE_TRANSACTIONS_ERROR,
  error: true,
  payload: e,
});
export const createTransRequest = () => ({
  type: CREATE_TRANSACTIONS_REQUEST,
  // payload: { queryParams },
});

export const createTransSuccess = response => ({
  type: CREATE_TRANSACTIONS_SUCCESS,
  payload: { data: response?.data },
});

export const createTransError = e => ({
  type: FETCH_TRANSACTIONS_ERROR,
  error: true,
  payload: e,
});

export const updateTransRequest = () => ({
  type: UPDATE_TRANSACTIONS_REQUEST,
  // payload: { queryParams },
});

export const updateTransSuccess = response => ({
  type: UPDATE_TRANSACTIONS_SUCCESS,
  payload: { data: response?.data },
});

export const updateTransError = e => ({
  type: UPDATE_TRANSACTIONS_ERROR,
  error: true,
  payload: e,
});

export const checkuserRequest = () => ({
  type: USER_ALREADY_EXIST_REQUEST,
});
export const checkuserError = error => ({
  type: USER_ALREADY_EXIST_ERROR,
  payload: error,
  error: true,
});
export const checkuserSuccess = bool => ({
  type: USER_ALREADY_EXIST_SUCCESS,
  payload: bool,
});

export const queryListings = queryParams => (dispatch, getState, sdk) => {
  dispatch(queryListingsRequest(queryParams));

  const { perPage, ...rest } = queryParams;
  const params = { ...rest, perPage };

  return sdk.listings
    .query(params)
    .then(async response => {
      dispatch(queryListingsSuccess(response));
      dispatch(addMarketplaceEntities(response, {}));
      return response;

    })
    .catch(e => {
      dispatch(queryListingsError(storableError(e)));
      throw e;
    });
};

export const queryTimeSlots = listingId => (dispatch, getState, sdk) => {
  dispatch(queryTimeSlotsRequest());

  const params = {
    listingId: new UUID(listingId),
    start: moment()
      .utc()
      .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
    end: moment()
      .utc()
      .add(90, 'days')
      .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
  };

  return sdk.timeslots
    .query(params)
    .then(response => {
      dispatch(queryTimeSlotsSuccess(response));
      dispatch(addMarketplaceEntities(response, {}));
      return response;
    })
    .catch(e => {
      dispatch(queryTimeSlotsError(storableError(e)));
      throw e;
    });
};

export const queryTransaction = listingId => (dispatch, getState, sdk) => {

  dispatch(queryTransactionRequest());
  const searchFilterArray = [
    { type: 'pagination', keyName: 'page', keyFilter: '', keyValue: 1 },
    { type: 'pagination', keyName: 'pageSize', keyFilter: '', keyValue: 100 },
    { type: 'filters', keyName: 'listingId', keyFilter: '$eq', keyValue: listingId },
  ];
  return onGetApiCollecton({ API_TYPE: STRAPI_TRANSACTIONS, data: { searchFilter: makeStrapiFilters(searchFilterArray) } })
    .then(response => {
      dispatch(queryTransactionSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(queryTransactionError(storableError(e)));
      throw e;
    });
};


export const createNewUser = (values, selectedListing, selectedDate,intl) => (dispatch, getState, sdk) => {
  const listingId = selectedListing?.id?.uuid;
  const { fname, lname, email, userName, teamMateId, dob,teamMateName, manual, paymentMode, TCGID } = values || {};
  const parsedDate = moment(dob, 'YYYY-MM-DD'); // Specify the format explicitly
 
  dispatch(createTransRequest());
  // Prepare user creation parameters
  const createUserParams = {
    firstName: fname,
    lastName: lname,
    email,
    password: "123456789",
    publicData: {
      userType: "private-person",
       dob:parsedDate.unix()*1000
    },
  };


  // Prepare booking parameters
  const bookingParams = {
    bookingEnd: selectedDate || null,
    userName: userName || null,
    bookingStart: selectedDate || null,
    teammateName: teamMateName || null,
    teamMemberId: teamMateId || null,
    manualPaymentMode: paymentMode,
    fname:fname,
    lname:lname,
    isManual: manual,
    TCGID: TCGID

  };

  // Check if the user exists, otherwise create a new user
  checkandCreateNewUser({ email })
    .then((userResponse) => {
      return userResponse ? userResponse : sdk.currentUser.create(createUserParams, { expand: true });
    })
    .then((userResponse) => {
      const userData = userResponse?.data?.data?.data??userResponse?.data?.data;
      const ManualButton = true;
      // Generate transaction parameters
      const type = "transaction-initiation";
      const strapiTransactionParam = onGetTransactionParam(
        userData,
        selectedListing,
        bookingParams,
        type,
        teamMateName,
        teamMateId,
        intl,
        ManualButton
      )
     

      // Create the transaction
      return onCreateTransaction({ strapiTransactionParam, chatMessage: "" });
    })
    .then(() => {
      dispatch(createTransSuccess());
    }).then(() => {
      dispatch(queryTransaction(listingId))
    })
    .catch((error) => {
      console.error("Transaction creation error:", error);
      dispatch(createTransError(storableError(error)));
      throw error;
    });
};

export const updateTransaction = (params) => (dispatch, getState, sdk) => {
  dispatch(updateTransRequest());

  const { listingId, updateParams } = params;

  return updateTransactionById(updateParams)
    .then(response => {
      dispatch(updateTransSuccess(response));
      dispatch(queryTransaction(listingId));

      return response;
    })
    .catch(e => {
      dispatch(updateTransError(storableError(e)));
      throw e;
    });
};
export const checkUserAlreadyExist = (params) => async (dispatch, getState, sdk) => {
  dispatch(checkuserRequest());
  try {
    const response = await onCheckUserAlreadyExist(params);
    const { isUserExist } = response

    dispatch(checkuserSuccess(isUserExist));
  } catch (error) {
    dispatch(checkuserError(storableError(error)));
  }
};

export const loadData = (params, search, config) => (dispatch, getState, sdk) => {
  const queryParams = parse(search);
  const page = 1;
  const { id } = queryParams || {};
  if(id){dispatch(queryTransaction(id));}
  
  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  return Promise.all([
    dispatch(
      queryListings(
        {
          ...queryParams,
          page,
          include: ['author', 'images', 'author.profileImage'],
          'fields.listing': [
            'title',
            'geolocation',
            'availabilityPlan',
            'price',
            'createdAt',
            'publicData',
            'deleted',
            'state',
            'publicData.listingType',
            'publicData.transactionProcessAlias',
            'publicData.unitType',
            // These help rendering of 'purchase' listings,
            // when transitioning from search page to listing page
            'publicData.pickupEnabled',
            'publicData.shippingEnabled',
          ],
          'fields.user': [
            'profile.displayName',
            'profile.abbreviatedName',
            'profileImage',
            'profile.publicData',
          ],
          'fields.image': [
            'variants.square-small',
            'variants.square-small2x',
            'variants.scaled-small',
            'variants.scaled-medium',
            `variants.${variantPrefix}`,
            `variants.${variantPrefix}-2x`,
          ],
          ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
          ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
          'limit.images': 1,
        },
        config
      )
    ),
  ])
    .then(response => {
      return response;
    })
    .catch(e => {
      throw e;
    });
};
