import starwarsLabel from '../assets/starwars.png';
import alteredImg from '../assets/Altered.png';
import finalFantasyImg from '../assets/Final Fantasay TCG.png';
import forceOfWillImg from '../assets/Force of Will.png';
import grandImg from '../assets/Grand Archive.png';
import gundamImg from '../assets/Gundam Card Game.png';
import keyForgeImg from '../assets/KeyForge.png';
import schwarzImg from '../assets/Schwarz Wei�.png';
import shadowverseImg from '../assets/Shadowverse.png';
import vanguardImg from '../assets/Vanguard.png';
import worldofWarImg from '../assets/World of Warcraft.png';
import { PAY_BANK, PAY_VOR_ORT, PAYPAL } from '../config/configListing';

export const gameLabels = [
  {
    label: 'Digimon',
    key: 'digimon',
    icon:
      'https://sharetribe-assets.imgix.net/6565a2b4-7a23-4278-841c-7684a015c012/raw/3e/f1a148f0591d40695f9de0381b08998b426470?auto=format&crop=edges&fit=crop&h=225&w=400&s=f5eda47283d3435cd545d6bb262245a8',
  },
  {
    label: 'Flesh and Blood',
    key: 'flesh-and-blood',
    icon:
      'https://sharetribe-assets.imgix.net/6565a2b4-7a23-4278-841c-7684a015c012/raw/7f/57fdb3d9e92a591bc2157e6edf1a5e6858a0b5?auto=format&crop=edges&fit=crop&h=225&w=400&s=c8604a78238aebb99115fcda06acbafe',
  },
  {
    label: 'Lorcana',
    key: 'lorcana',
    icon:
      'https://sharetribe-assets.imgix.net/6565a2b4-7a23-4278-841c-7684a015c012/raw/27/731085558505ef9626af33557ff4ebad38d4fb?auto=format&crop=edges&fit=crop&h=225&w=400&s=f99fd74c17a87b4d215a1b05957f4af3',
  },
  {
    label: 'Magic the Gathering',
    key: 'magic-the-gathering',
    icon:
      'https://sharetribe-assets.imgix.net/6565a2b4-7a23-4278-841c-7684a015c012/raw/7b/57344a23546c393f15bcf816c86c31584d8e5d?auto=format&crop=edges&fit=crop&h=225&w=400&s=3c02ca303d91271d0e4aa448cdbb1c03',
  },
  {
    label: 'One Piece',
    key: 'one-piece',
    icon:
      'https://sharetribe-assets.imgix.net/6565a2b4-7a23-4278-841c-7684a015c012/raw/3f/89a82cd18d61b6f98d5881eeff891b8cc6ca19?auto=format&crop=edges&fit=crop&h=225&w=400&s=18e9f00a921123d9c4eb80db09cd4a3c',
  },
  {
    label: 'Pokemon',
    key: 'pokemon',
    icon:
      'https://sharetribe-assets.imgix.net/6565a2b4-7a23-4278-841c-7684a015c012/raw/18/15832fac42a19baedf59bcf374d6fde88e61db?auto=format&crop=edges&fit=crop&h=225&w=400&s=618a34b29c25d60cf0d6f33f56ffd343',
  },
  {
    label: 'Yu-Gi-Oh!',
    key: 'yu-gi-oh',
    icon:
      'https://sharetribe-assets.imgix.net/6565a2b4-7a23-4278-841c-7684a015c012/raw/87/e83e3ebd19b402ad8871202e318cd875401224?auto=format&crop=edges&fit=crop&h=225&w=400&s=59a67556d26055b6f14977c5495cc01e',
  },
  {
    label: 'Star Wars',
    key: 'star-wars',
    icon: starwarsLabel,
  },
  {
    label: 'Dragon Ball Super Card Game',
    key: 'dbscg',
    icon:
      'https://sharetribe-assets.imgix.net/6565a2b4-7a23-4278-841c-7684a015c012/raw/9a/6a3ceb6d60de4c812152a32d14ca6b3e24d404?auto=format&crop=edges&fit=crop&h=225&w=400&s=7614d208fefc2fe65686d32a11819885',
  },
  {
    label: 'Altered',
    key: 'altered',
    icon: alteredImg,
  },
  {
    label: 'Final Fantasy Trading Card Game',
    key: 'fftcg',
    icon: finalFantasyImg,
  },
  {
    label: 'Force of Will',
    key: 'fow',
    icon: forceOfWillImg,
  },
  {
    label: 'Grand Archive',
    key: 'grand',
    icon: grandImg,
  },
  {
    label: 'Gundam Card Game',
    key: 'gundam',
    icon: gundamImg,
  },
  {
    label: 'KeyForge',
    key: 'keyForge',
    icon: keyForgeImg,
  },
  {
    label: 'Weiß/Schwarz',
    key: 'schwarz',
    icon: schwarzImg,
  },
  {
    label: 'Shadowverse',
    key: 'shadowverse',
    icon: shadowverseImg,
  },
  {
    label: 'Cardfight!! Vanguard',
    key: 'cardfight',
    icon: vanguardImg,
  },
  {
    label: 'World of Warcraft TCG',
    key: 'warcraft',
    icon: worldofWarImg,
  },
];
export const PaymentSetting = [
  {
    label: 'PayPal',
    key: PAYPAL,
  },
  {
    label: 'Bank',
    key: PAY_BANK,
  },
  {
    label: 'vorOrt',
    key: PAY_VOR_ORT,
  },
];
export const selectedPaymentMethod = [
  {
    label: 'PayPal',
    value: 'payPal',
    key: PAYPAL,
  },
  {
    label: 'Banküberweisung',
    value: 'bank',
    key: PAY_BANK,
  },
  {
    label: 'Vor Ort',
    value: 'vorOrt',
    key: PAY_VOR_ORT,
  },
];

export const paymentOptionsCheckOut = [
  {
    id: 'payVia-id1',
    label: 'per PayPal (Vorkasse)',
    value: 'payPal',
    fieldName: 'pay-paypal',
  },
  {
    id: 'payVia-id2',
    label: 'per Banküberweisung (Vorkasse)',
    value: 'bank',
    fieldName: 'pay-bank',
  },
  {
    id: 'payVia-id3',
    label: 'Vor Ort',
    value: 'vorOrt',
    fieldName: 'pay-vor-ort'
  }]
export const acceptPaymentOptions = [
  {
    id: 'accept-automatically-true',
    brand: 'robot',
    labelId: 'EditListingPricingForm.acceptAutomatically',
    value: 'true',
    tooltipId: 'EditListingPricingForm.acceptAutomaticallyTooltip',
  },
  {
    id: 'accept-automatically-false',
    brand: 'mouse',
    labelId: 'EditListingPricingForm.acceptManually',
    value: 'false',
    tooltipId: 'EditListingPricingForm.acceptManuallyTooltip',
  },
  {
    id: 'accept-automatically-link',
    brand: 'arrowup',
    labelId: 'EditListingPricingForm.payLinkToAnotherWebPage',
    value: 'link',
    tooltipId: 'EditListingPricingForm.payLinkToAnotherTooltip',
  },
];
export const formatMatcher = [
  { sealed: 'Sealed' },
  { draft: 'Draft' },
  { 'classic-const': 'Classic Constructed' },
  { constructed: 'Constructed' },
  { 'digimon-other': 'Sonstiges' },
  { blitz: 'Blitz' },
  { 'ultimate-pit': 'Ultimate Pit Fight' },
  { commoner: 'Commoner' },
  { 'flesh-and-blood-classic-other': 'Sonstiges' },
  { clash: 'Clash' },
  { 'lorcana-core-constructed': 'Core Constructed' },
  { standard: 'Standard' },
  { 'lorcana-other': 'Sonstiges' },
  { pioneer: 'Pioneer' },
  { modern: 'Modern' },
  { legacy: 'Legacy' },
  { vintage: 'Vintage' },
  { commander: 'Commander' },
  { brawl: 'Brawl' },
  { oldschool: 'Oldschool' },
  { premodern: 'Premodern' },
  { pauper: 'Pauper' },
  { 'magic-the-gathering-other': 'Sonstiges' },
  { 'one-piece-other': 'Sonstiges' },
  { expanded: 'Expanded' },
  { unlimited: 'Unlimited' },
  { deck: 'Theme Deck' },
  { 'pokemon-other': 'Sonstiges' },
  { advanced: 'Advanced' },
  { traditional: 'Traditional' },
  { 'speed-duel': 'Speed Dual' },
  { 'common-charity': 'Common Charity' },
  { goat: 'Goat' },
  { edison: 'Edison' },
  { hat: 'H.A.T.' },
  { 'time-wizard': 'Time Wizard' },
  { 'yu-gi-oh-other': 'Sonstiges' },
  { permier: 'Premier' },
  { 'twin-suns': 'Twin Suns' },
  { eternal: 'Eternal' },
  { multiplayer: 'Multiplayer' },
  { 'standard-const': 'Standard Constructed' },
  { 'L3-constructed': 'L3-Constructed' },
  { 'L6-constructed': 'L6-Constructed' },
  { 'side-constructed': 'Side Constructed' },
  { 'neo-standard': 'Neo Standard' },
  { 'v-premium': 'V Premium' },
  { premium: 'Premium' },
  { 'extreme-fight': 'Extreme Fight' },
  { 'new-frontiers': 'New Frontiers' },
  { wanderer: 'Wanderer' },
  { cluster: 'Cluster' },
  { origin: 'Origin' },
  { marchenzeit: 'Märchenzeit' },
  { classic: 'Classic' },
  { core: 'Core' },
  { 'lazy-peon': 'Lazy Peon' },
  { raid: 'Raid' },
  { battleground: 'Battleground' },
  { archon: 'Archon' },
  { retro: 'Retro-Quintupel' },
  { unbegrenzt: 'Unbegrenzt' },
  { favoriten: 'Favoriten Quintupel' },
  { nimm: 'Nimm 2' },
  { teamkampf: 'Teamkampf' },
  { 'battle-royale': 'Battle Royale' },
  { other: 'Other' },
];
export const paymentSettingTypes = PaymentSetting.map(p => p.key);
export const tradingCardTypes = gameLabels.map(g => g.key);
